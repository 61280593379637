@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');


@font-face {
    font-family: "Rotoplas-Bold";
    src: url(../fonts/Rotoplas-Bold-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Bold-I";
    src: url(../fonts/Rotoplas-Bold_Italic-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Light";
    src: url(../fonts/Rotoplas-Light-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Light-I";
    src: url(../fonts/Rotoplas-Light_Italic-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Regular";
    src: url(../fonts/Rotoplas-Regular-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Regular-I";
    src: url(../fonts/Rotoplas-Regular_Italic-Web.otf);
}


.login{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.login-container{
    padding: 30px;
    background-color: #FFF;
    font-family: "Rotoplas-Regular";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #002554;
    width: 500px;
    height: 100%;
    border-radius: 10px;
    border-color: #8BD2F4;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}



.login-section {
    position: relative;
    padding: 15px 0 0;
    margin-top: 20px;
    width: 80%;
  }
  
  .login-input {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.2rem;
    color: #002554;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
  }
  .login-input::placeholder {
    color: transparent;
  }
  .login-input:placeholder-shown ~ .login-label {
    font-size: 1.2rem;
    cursor: text;
    top: 20px;
  }
  
  .login-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1.2rem;
    color: #002554;
  }
  
  .login-input:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #009CDE, #236192);
    border-image-slice: 1;
  }
  .login-input:focus ~ .login-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1.2rem;
    color: #FFF;
    font-weight: 700;
  }
  
  /* reset input */
  .login-input:required, .login-input:invalid {
    box-shadow: none;
  }



.btn-pos{
    margin: 2%;
    background-color: #00AFD7;
    color: #FFFFFF;
    width: fit-content;
}

/*Responsive*/
@media screen and (max-width: 650px) {
    .login-container{
        width: 300px;
    }
    
}