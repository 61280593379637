/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');

@font-face {
    font-family: "Rotoplas-Bold";
    src: url(utils/fonts/Rotoplas-Bold-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Bold-I";
    src: url(utils/fonts/Rotoplas-Bold_Italic-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Light";
    src: url(utils/fonts/Rotoplas-Light-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Light-I";
    src: url(utils/fonts/Rotoplas-Light_Italic-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Regular";
    src: url(utils/fonts/Rotoplas-Regular-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Regular-I";
    src: url(utils/fonts/Rotoplas-Regular_Italic-Web.otf);
}


.App {
  text-align: center;
  background-color: #002554;
  min-height: 100vh;
}

.main-logo{
  position: absolute;
  top: 2%;
  left: 2%;
  max-width: 30%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.spinner-position{
  position: fixed;
  display: flex;
  min-height: 100%;
  min-width: 100%;
  justify-content: center;
  align-items: center;
}
.spinner-styles{
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*Responsive*/
@media screen and (max-width: 650px) {
  .main-logo{
      position: absolute;
      top: 3%;
      left: 3%;
      max-width: 25%;
  }
  
}


