/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');

@font-face {
    font-family: "Rotoplas-Bold";
    src: url(../fonts/Rotoplas-Bold-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Bold-I";
    src: url(../fonts/Rotoplas-Bold_Italic-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Light";
    src: url(../fonts/Rotoplas-Light-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Light-I";
    src: url(../fonts/Rotoplas-Light_Italic-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Regular";
    src: url(../fonts/Rotoplas-Regular-Web.otf);
}

@font-face {
    font-family: "Rotoplas-Regular-I";
    src: url(../fonts/Rotoplas-Regular_Italic-Web.otf);
}


.logout-button{
    position: absolute;
    top: 2%;
    right: 2%;
}


.container-form{
    padding: 30px;
    background-color: #002554;
    font-family: "Rotoplas-Regular";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #FFFFFF;
}

.title-form{
    margin-left: 1%;
    margin-top: 1%;
    font-weight: 10;
}

.row-form{
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
}

.row-title{
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 20px;
}

.item-form{
    display: flex;
    flex-flow: column;
    width: 30%;
    height: 65px;
}

.row-buttons{
    display: flex;
    flex-flow: row;
    justify-content:center;
}

.btn-pos{
    margin: 2%;
    background-color: #00AFD7;
    color: #FFFFFF;
}

.input-design{
    height: 60%;
}

.product-part{
    margin-top: 20px;
    padding-top: 20px;
    border-color: #FFFFFF;
    border-style: groove none none none;
}

/*Search client*/
.search-container {
    height: 60%;
    display: flex;
    flex-direction: column;
}

.input-search{
    height: 100%;
}
  
.dropdown {
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
}
  
.dropdown:empty {
    border: none;
}
  
.dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
    color: #002554;
}

/*Responsive*/
@media screen and (max-width: 650px) {
    .row-form{
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    
    .item-form{
        width: 70%;
    }

    .main-logo{
        position: absolute;
        top: 3%;
        left: 3%;
        max-width: 25%;
    }
    
}